import { breakpoints } from 'src/styles/breakpoints'
import { brand, grayscale, red, white } from 'src/styles/colors'
import { orange } from 'src/styles/newColors'
import styled from 'styled-components'

import BgDesktopLgInterDay from './../../assets/image/bg-hero-inter-day-v5-desktop-lg.webp'
import BgDesktopXlInterDay from './../../assets/image/bg-hero-inter-day-v5-desktop-xl.webp'
import BgTabletInterDay from './../../assets/image/bg-hero-inter-day-v5-tablet.png'

type DataProps = {
  isOpenSelect?: boolean;
}

export const Section = styled.section<DataProps>`
  position: relative;
  background: #ea7101;
  border-radius: 0 0 32px 32px;
  height: 624px;

  @media (min-width: ${breakpoints.sm}){
    background-position-y: -56px;
    background-position-x: 170px;
  }
  @media (min-width: ${breakpoints.md}){
    background: #ea7101 url(${BgTabletInterDay}) no-repeat center center;
    height: 439px;
    background-size: contain;
  }
  @media (min-width: ${breakpoints.lg}){
    background: #ea7101 url(${BgDesktopLgInterDay}) no-repeat center center;
    background-size: contain;
    height: 551px;
  }
  @media (min-width: ${breakpoints.xl}){
    background: #ea7101 url(${BgDesktopXlInterDay}) no-repeat center center;
    background-size: cover;
    height: 660px;
  }
  @media (min-width: ${breakpoints.xxll}){
    background-position-y: -170px;
  }

  p.cadastre {
    @media (min-width: ${breakpoints.xl}){
      font-size: 18px !important;
      line-height: 22px !important;
    }
  }

  .ml-desconto {
    @media (min-width: ${breakpoints.xxxl}){
      margin-left: 40px !important;
    }

    @media (min-width: ${breakpoints.xxll}){
      margin-left: -0.25rem !important
    }
  }

  .box {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .margin-top-cashback {
    @media (min-width: ${breakpoints.md}){
      margin-top: 0.5rem;
    }
    @media (min-width: ${breakpoints.xxxl}){
      margin-top: 1.5rem;
    }
    @media (min-width: ${breakpoints.xxll}){
      margin-top: 2rem;
    }
  }
`

export const Check = styled.button`
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
`

export const Card = styled.div<DataProps>`
  width: 100%;
  background: ${orange.clay};
  border-radius: 16px;
  padding: 17px;
  color: ${white};
  position: relative;
  margin: 0 auto;

  @media (min-width: ${breakpoints.md}){
    padding: 24px;
    float: right;
    z-index: ${(props: DataProps) => props.isOpenSelect ? 10 : 1};
  }
  @media (min-width: ${breakpoints.lg}){
    max-width: 479px;
  }
  @media (min-width: ${breakpoints.xl}){
    margin-bottom: 0;
  }

  input {
    height: 48px;
    width: 100%;
    background: ${orange.dark};
    border: none;
    border-radius: 8px;
    padding: 0 16px;
    color: ${white};

    &:focus{
      outline:none;
    }

    &::placeholder{
      font-size: 14px;
      line-height: 18px;
      color: ${white};
    }
  }

  .error {
    label, p {
      color: ${white};
    }
    input, details {
      background: #FEF0ED;
      
      ::placeholder {
        color: ${red[500]};
      }
    }
    summary {
      p {
        color: ${red[500]} !important;
      }
    }

    button {
      span {
        color: ${grayscale[400]} !important;
      }
    }
  }
`

export const CardSelect = styled.div<DataProps>`
  display: ${(props: DataProps) => props.isOpenSelect ? 'block' : 'none'};

  span {
    margin-bottom: 1px;
  }
`

export const Select = styled.details`
  min-height: 48px;
  width: 100%;
  background: ${orange.dark};
  border-radius: 8px;
  overflow: hidden;
  padding: 12px;
  color: ${white};

  .chevron{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
`

export const Summary = styled.summary`
  list-style: none;
  width: 100%;
  margin-top: 3px;
  position: relative;
`

export const Button = styled.button`
  background: ${orange.extra};
  height: 48px;
  width: 100%;
  border-radius: 8px;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  border: none;

  &:focus {
  outline: none;
  }

  &:disabled{
  background: ${grayscale[200]};
  color: #B6B7BB;
  }
`

export const Faixa = styled.div`
  background-color: ${grayscale[500]};
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
`

export const CountDown = styled.div`
  background: #FFFF29;
  border-radius: 4px;
  height: 21px ;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  & + div {
    margin-left: 3px;
  }
`

export const Time = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: ${white};
`

export const Text = styled.span`
  font-size: 8px;
  line-height: 9px;
  color: ${white};
`

export const HeroButton = styled.a`
  height: 57px;
  background: ${brand.claryOrange};
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 35px;
  justify-content: center;

  @media (min-width: ${breakpoints.md}){
    margin-top: 10px;
    max-width: 316px;
  }
  @media (min-width: ${breakpoints.lg}){
    margin-top: 45px;
    max-width: 401px;
  }
  @media (min-width: ${breakpoints.xl}){
    margin-top: 25px;
    max-width: 447px;
  }
`
