export interface IWbUserInfo {
  name: string;
  email: string;
  cellPhoneNumber: string;
  cpf: string;
  account: string;
  birthDate: number;
  phoneNumber: string;
  userType: 'basic_account' | 'complete_account' | 'remittance_account' | 'bank_account';
  segmentation: 'DIGITAL' | 'ONE' | 'BLACK' | 'WIN' | 'BASIC' | 'PJ PRO' | 'PJ ENTERPRISE' | 'MIDDLE MARKET' | 'CORPORATE';
  bankAccountList?: {
    cpfCnpj: string;
    name: string;
    account: string;
    current: boolean;
  }[];
  operator?: string;
}

const userInfo: IWbUserInfo = {
  account: '12345678',
  cpf: '12345678909',
  name: 'User',
  email: 'user@bancointer.com.br',
  phoneNumber: '31999999999',
  cellPhoneNumber: '',
  birthDate: 0,
  userType: 'basic_account',
  segmentation: 'DIGITAL',
}

export default userInfo
