import React, { useEffect, useState } from 'react'

import Close from '@interco/icons/build-v4/bidis/v2/navigation/close'
import Error from '../../../assets/image/error.png'
import Success from '../../../assets/image/success.png'

import ScrollTo from 'src/components/ScrollTo'
import { ClickButton, CloseButton, Modal } from './style'

const menssagens = {
  error: {
    title: 'Algo deu errado',
    paragraph: '<p class="fs-14 lh-17 text-grayscale--500 text-center">Não foi possível concluir seu cadastro. Atualize a página, confirme seus dados e preencha o formulário novamente.</p>',
    paragraph2: '',
    paragraph3: '',
  },
  success: {
    title: '<span class=\'d-block font-citrina\'>Parabéns!</span> <span class=\'font-citrina\'>Agora você é VIP!</span>',
    paragraph: '<p class="fs-14 lh-17 fw-600 text-grayscale--500"> Em breve, você receberá um e-mail com seu cupom pra comprar no Shopping, pelo Super App, durante o Inter Day.</p>',
    paragraph2: '<p class="fs-14 lh-17 text-grayscale--500 mt-2">Enquanto isso, confira as ofertas que já estão rolando por aqui!</p>',
    paragraph3: 'Ainda não tem conta no Inter? <a href=\'https://intergo.app/69babd4c\' class=\'text-orange--extra\'>Abra sua conta 100% digital e gratuita!</a>',
  },
}

type ModalMensssageProps = {
  type: string;
  setOpen: Function;
}

const ModalMenssage = ({ setOpen, type }: ModalMensssageProps) => {
  const [ iWb, setIWb ] = useState<typeof import('@interco/inter-webview-bridge') | null>(null)

  const handleClick = () => {
    setOpen(false)
  }

  useEffect(() => {
    import('@interco/inter-webview-bridge').then((interBridge: typeof import('@interco/inter-webview-bridge')) => {
      setIWb(interBridge)
    })
  }, [])

  return (
    <Modal>
      <div className='relative'>
        <CloseButton onClick={() => handleClick()}>
          <ScrollTo
            to='#hero-inter-day'
            section='dobra_01'
            elementName='Entendi'
            sectionName='Inter Day 2024'
          >
            <Close height={24} width={24} color='#FF7A00' />
          </ScrollTo>
        </CloseButton>
        {
          type === 'success' ? <img src={Success} /> : <img src={Error} />
        }
        <h3
          className='fs-24 lh-28 text-grayscale--500 fw-600 text-center mt-3'
          dangerouslySetInnerHTML={{ __html: menssagens[type as 'success' | 'error'].title }}
        />
        <div
          className='fs-14 lh-17 text-grayscale--500 text-center'
          dangerouslySetInnerHTML={{ __html: menssagens[type as 'success' | 'error'].paragraph }}
        />
        <div
          className='fs-14 lh-17 text-grayscale--500 text-center'
          dangerouslySetInnerHTML={{ __html: menssagens[type as 'success' | 'error'].paragraph2 }}
        />
        {type === 'success'
          ? (
            <ClickButton
              onClick={() => iWb?.interWbNavigate.openDeepLink('https://intergo.app/20cf4951')}
              title='Mostrar Ofertas'
            >Mostrar Ofertas
            </ClickButton>
          )
        : (
          <ScrollTo
            to='#hero-inter-day'
            section='dobra_01'
            elementName='Entendi'
            sectionName='Inter Day 2024'
          >
            <ClickButton
              title='Entendi'
              onClick={() => handleClick()}
            >Entendi
            </ClickButton>
          </ScrollTo>
          )
        }
        <div
          className='fs-12 lh-14 text-grayscale--500 mt-4 pt-2'
          dangerouslySetInnerHTML={{ __html: menssagens[type as 'success' | 'error'].paragraph3 }}
        />
      </div>
    </Modal>
  )
}

export default ModalMenssage
